import React, { useEffect } from 'react';
import { generatePath, Redirect, Route, Switch, useParams } from 'react-router-dom';
import routes from 'src/next/navigation/routes';
import CertificationDashboardMain from './CertificationDashboardMain';
import CertificationStepMain from './CertificationStepMain';
import { getCurrentCertificationCycle } from './certificationUtil';
import useStore from '../store';

export default function CertificationMain() {
  const { certificationId } = useParams();
  const {
    certifications,
    getCertificationById,
    resetCertifications,
    certificationCycles,
    getCertificationCycles,
    resetCertificationCycles,
    certificationSteps,
    getCertificationSteps,
    resetCertificationSteps,
    lastCertificationActivity,
    getLatestCertificationActivity,
    resetCertificationActivities,
    getPillars
  } = useStore(state => state.certification);
  const { psTeamMembers } = useStore(state => state.user); // PS team members are fetched in AppBar

  useEffect(() => {
    getCertificationById(certificationId);
    getCertificationCycles(certificationId);
    getCertificationSteps(certificationId);
    getLatestCertificationActivity(certificationId);

    return () => {
      resetCertifications();
      resetCertificationCycles();
      resetCertificationSteps();
      resetCertificationActivities();
    };
  }, [
    certificationId,
    getCertificationById,
    resetCertifications,
    getCertificationSteps,
    resetCertificationSteps,
    resetCertificationActivities
  ]);

  const shownCertification = certifications.find(c => c.id === +certificationId);
  const currentCertificationCycle = getCurrentCertificationCycle(certificationCycles);

  useEffect(() => {
    if (currentCertificationCycle) {
      getPillars(certificationId, currentCertificationCycle.id);
    }
  }, [certificationId, currentCertificationCycle]);

  return (
    !shownCertification
      ? null
      : <Switch>
        <Route path={routes.CERTIFICATION} exact>
          <CertificationDashboardMain
            certification={shownCertification}
            certificationCycle={currentCertificationCycle}
            certificationSteps={certificationSteps}
            lastCertificationActivity={lastCertificationActivity}
            psTeamMembers={psTeamMembers}
          />
        </Route>
        <Route path={routes.CERTIFICATION_STEP}>
          <CertificationStepMain
            certification={shownCertification}
            certificationCycle={currentCertificationCycle}
            certificationSteps={certificationSteps}
          />
        </Route>
        <Redirect to={generatePath(routes.CERTIFICATION, { certificationId })} />
      </Switch>
  );
}
