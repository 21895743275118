import axios from 'axios';
import { DateTime } from 'luxon';
import { API_URL } from '../../constants';

const DEFAULT_PAGE_SIZE = 10;
const NOTIFICATIONS_URL = `${API_URL}/notifications`;

const api = {
  async getSeenNotifications(certificationId) {
    const seenFrom = DateTime.now().minus({ days: 7 });

    return axios.get(NOTIFICATIONS_URL, { params: { certificationId, pageSize: DEFAULT_PAGE_SIZE, filter: 'SEEN', seenFrom } });
  },
  async getUnseenNotifications(certificationId) {
    return axios.get(NOTIFICATIONS_URL, { params: { certificationId, pageSize: DEFAULT_PAGE_SIZE, filter: 'UNSEEN' } });
  },
  async markCommentNotificationsSeen(commentIds) {
    const url = `${NOTIFICATIONS_URL}/seen`;

    return axios.patch(url, { commentIds });
  }
};

export default (set, get) => ({
  seenNotifications: [],
  unseenNotifications: [],

  async getNotifications(certificationId) {
    const seenNotifications = await api.getSeenNotifications(certificationId);
    const unseenNotifications = await api.getUnseenNotifications(certificationId);

    set(state => ({ seenNotifications, unseenNotifications }), 'GET_NOTIFICATIONS');
  },

  async markCommentNotificationsSeen(commentIds) {
    if (commentIds.length) {
      await api.markCommentNotificationsSeen(commentIds);
    }
  },

  // NOTE: Only marks locally, should be removed after SSE is implemented
  markNotificationSeen(notificationId) {
    const { unseenNotifications: previousUnseenNotifications, seenNotifications: previousSeenNotifications } = get();
    const notification = previousUnseenNotifications.find(n => n.id === notificationId);

    if (notification) {
      notification.seenAt = new Date().toISOString();

      const unseenNotifications = previousUnseenNotifications.filter(n => n.id !== notificationId);
      const seenNotifications = [...previousSeenNotifications, notification]
        .sort((a, b) => b.seenAt.localeCompare(a.seenAt));

      set(state => ({ seenNotifications, unseenNotifications }), 'MARK_NOTIFICATION_SEEN');
    }
  },

  resetNotifications() {
    set(state => ({ seenNotifications: [], unseenNotifications: [] }), 'RESET_NOTIFICATIONS');
  }
});
