import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material';
import {
  ChevronDown as ChevronDownIcon
} from 'react-feather';
import React from 'react';
import JourneyCycleDetails from './JourneyCycleDetails';

const StyledAccordionSummary = styled(AccordionSummary)`
  &.MuiAccordionSummary-root {
    justify-content: start;
  }

  .MuiAccordionSummary-content {
    flex-grow: 0;
  }

  .MuiAccordionSummary-expandIconWrapper {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;

const StyledCycleHeader = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.tertiary.lightest};
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 100%;
`;

function getJourneyByPosition(steps) {
  const journeyByPositionUnsorted = steps?.reduce((acc, step) => {
    const {
      type,
      certificationCycle: { id, position, cycleYear }
    } = step;
    const current = acc.get(position) || { certificationCycleId: id, steps: { [type]: [] }, position, cycleYear };

    current.steps[type].push(step);

    return acc.set(position, current);
  }, new Map());

  return Array.from(journeyByPositionUnsorted.values()).sort((a, b) => b.position - a.position);
}

function JourneyAccordionItems(certification, journeyByPosition, assessmentType) {
  return journeyByPosition.map(journey => {
    const { position, steps, cycleYear } = journey;
    const isBaseline = !!steps?.baseline;
    const stepsPerType = Object.entries(steps);
    const label = isBaseline ? 'Baseline' : cycleYear;

    return (
      <React.Fragment key={position}>
        <StyledCycleHeader>{label}</StyledCycleHeader>
        {stepsPerType.map(([type, steps]) =>
          steps.map((step, index) => (
            <JourneyCycleDetails
              key={`${position}-${type}-${index}`}
              certification={certification}
              assessment={step}
              currentPosition={position}
              baseline={type === 'baseline' ? step : undefined}
              evaluation={type === 'evaluation' ? step : undefined}
              isBaseline={isBaseline}
              cycleYear={cycleYear}
              assessmentType={assessmentType}
            />
          ))
        )}
      </React.Fragment>
    );
  });
}

export default function JourneyAccordion({
  assessmentType,
  certification,
  certificationSteps,
  title
}) {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandedChange = (_event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const journeyByPosition = getJourneyByPosition(certificationSteps);
  if (!journeyByPosition.length) return null;

  return (
    <Box mt={2.5} position="relative">
      <Accordion disableGutters expanded={expanded} onChange={handleExpandedChange} square>
        <StyledAccordionSummary
          expandIcon={<ChevronDownIcon width={16} height={16} />}
          aria-controls="testing-data-content"
        >
          <Typography variant="subtitle2">{title}</Typography>
        </StyledAccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {JourneyAccordionItems(certification, journeyByPosition, assessmentType)}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
