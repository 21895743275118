import React, { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ROLES } from 'src/constants';
import ContactPsTeamMembersWidget from './ContactPsTeamMembersWidget';
import CertificationJourney from './CertificationJourney';
import CertificationProgressGuidance from './CertificationProgressGuidance';
import ResourceList from '../resource/ResourceList';
import CertificationEmployeeList from '../certification/CertificationEmployeeList';
import GettingStartedWidget from '../getting-started/GettingStartedWidget';
import useStore from '../store';

const RESOURCES = [
  {
    title: 'MLT Racial Equity at Work Certification Guide',
    href: '/static/next/MLT-racial-equity-at-work-certification-guide.pdf'
  },
  {
    title: 'MLT Racial Equity at Work Offline Assessment Workbook',
    href: '/static/next/MLT-racial-equity-at-work-offline-assessment-workbook-july-2024.xlsx'
  },
  {
    title: 'MLT Racial Equity at Work Commitment Communication Toolkit',
    href: '/static/next/MLT-racial-equity-at-work-commitment-communications-toolkit-2024.pdf'
  },
  {
    title: 'MLT Racial Equity at Work Certified Communication Toolkit',
    href: '/static/next/MLT-racial-equity-at-work-certified-communications-toolkit-2024.pdf'
  }
];

export default function CertificationDashboardMain({
  certification,
  certificationCycle,
  certificationSteps = [],
  lastCertificationActivity,
  psTeamMembers
}) {
  const {
    users,
    getEmployees,
    resetUsers
  } = useStore(state => state.user);
  const {
    beginNextStep
  } = useStore(state => state.certification);
  const { user } = useStore(state => state.auth);

  useEffect(() => {
    getEmployees(certification.id);

    return () => {
      resetUsers();
    };
  }, [
    certification.id,
    getEmployees,
    resetUsers
  ]);
  const beginDisabled = user?.role === ROLES.STAFF;
  const onBegin = useCallback(() => beginNextStep(certification.id));

  return (
    <Grid container spacing={2} columns={10}>
      <Grid item xs={6}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <CertificationProgressGuidance certification={certification} />
          </Grid>
          <Grid item>
            <CertificationJourney
              certification={certification}
              certificationCycle={certificationCycle}
              certificationSteps={certificationSteps}
              lastCertificationActivity={lastCertificationActivity}
              beginDisabled={beginDisabled}
              onBegin={onBegin}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <ContactPsTeamMembersWidget psTeamMembers={psTeamMembers} />
          </Grid>
          <Grid item>
            <GettingStartedWidget />
          </Grid>
          <Grid item>
            <ResourceList title='Supplier.io' isSupplierIo />
          </Grid>
          <Grid item>
            <ResourceList title='Key Resources' keyResources={RESOURCES} />
          </Grid>
          <Grid item>
            <CertificationEmployeeList certificationId={certification.id} employees={users} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
