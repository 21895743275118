import React from 'react';
import assessmentType from 'src/next/assessment/assessmentType';

const BadgeTypes = Object.freeze({
  PLATINUM: 'platinum',
  GOLD: 'gold',
  SILVER: 'silver',
  BRONZE: 'bronze'
});

// Autogenerated list of supported years
const generateSupportedYearsList = () => {
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  const length = currentYear - startYear + 1;

  return Array.from({ length }, (_, i) => startYear + i);
};
const supportedYears = generateSupportedYearsList();

// Badge selection and path generation
const generateBadgePath = (assessment, badge, year) =>
  `/static/next/badges/${assessment.toLowerCase()}/${year}/${badge}.png`;

const createBadgeSet = (assessment, year) => ({
  [BadgeTypes.PLATINUM]: generateBadgePath(assessment, BadgeTypes.PLATINUM, year),
  [BadgeTypes.GOLD]: generateBadgePath(assessment, BadgeTypes.GOLD, year),
  [BadgeTypes.SILVER]: generateBadgePath(assessment, BadgeTypes.SILVER, year),
  [BadgeTypes.BRONZE]: generateBadgePath(assessment, BadgeTypes.BRONZE, year)
});

const badgesByAssessmentTypeAndYear = supportedYears.reduce((acc, year) => {
  acc[year] = {
    [assessmentType.BEW]: createBadgeSet('bew', year),
    [assessmentType.HEW]: createBadgeSet('hew', year)
  };
  return acc;
}, {});

export const getBadgeKeyByValue = value =>
  Object.keys(BadgeTypes).find(key => BadgeTypes[key] === value);

export const getBadgeType = score => {
  if (score >= 90) return BadgeTypes.PLATINUM;
  if (score >= 80) return BadgeTypes.GOLD;
  if (score >= 70) return BadgeTypes.SILVER;
  if (score >= 60) return BadgeTypes.BRONZE;
  return null;
};

const BadgeIcon = ({ assessmentType, score, year, isBaseline, ...props }) => {
  if (!supportedYears.includes(year)) return null;
  const badgesForYear = badgesByAssessmentTypeAndYear[year];
  if (!badgesForYear) return null;

  const badges = badgesForYear[assessmentType];
  if (!badges) return null;

  const badgeType = getBadgeType(score);
  const badgePath = badges?.[badgeType];
  if (!badgePath) return null;

  const visibilityStyle = isBaseline ? 'hidden' : 'visible';

  return (
    <img alt="Badge" src={badgePath} style={{ visibility: visibilityStyle }} {...props} />
  );
};

export default BadgeIcon;
