import { Clock as ClockIcon } from 'react-feather';
import { ExtendedCertificationStatus } from 'src/models/certification';
import logger from 'src/utils/logger';
import { CERTIFIED_SCORE } from 'src/constants';
import { getCertificationStatus, getStepRoute } from './certificationUtil';
import { getAssessmentWindowMeta } from './periodUtil';
import { periods } from './periodsConfig';

const {
  BASELINE_NOT_STARTED,
  BASELINE_DRAFT,
  BASELINE_REVIEW,
  EVALUATION_DRAFT,
  EVALUATION_NOT_STARTED,
  EVALUATION_NOT_STARTED_OPEN,
  EVALUATION_IN_REVIEW,
  EVALUATION_APPROVED,
  EVALUATION_APPROVED_NO_BADGE
} = ExtendedCertificationStatus;

const CERTIFICATION_MESSAGE = {
  PLATINUM: `Congratulations! You've achieved Platinum certification status, a testament to the investment your organization
    has made in implementing equitable business practices. Please continue your hard work and dedication to change in order to
    maintain the highest level of racial equity.`,
  GOLD: `Congratulations! You've achieved Gold certification status. With hard work and dedication to change, you can achieve
    Platinum certification status.`,
  SILVER: `Congratulations! You've achieved Silver certification status. With hard work and dedication to change, you can
    achieve Gold or Platinum certification status.`,
  BRONZE: `Congratulations! You've achieved Bronze certification status. With hard work and dedication to change, you can
    achieve Silver, Gold, or Platinum certification status.`
};

const getCertificationMessage = score => {
  if (score >= 90) return CERTIFICATION_MESSAGE.PLATINUM;
  if (score >= 80) return CERTIFICATION_MESSAGE.GOLD;
  if (score >= 70) return CERTIFICATION_MESSAGE.SILVER;
  if (score >= 60) return CERTIFICATION_MESSAGE.BRONZE;
};

const statusConfig = {
  // Hybrid certification status
  [BASELINE_NOT_STARTED]: {
    title: 'Baseline Assessment Not Started',
    message: `The Baseline Assessment helps MLT understand the current state of racial equity within your organization.
      This is the first step towards earning certification. Please click the button below to begin filling out your Baseline Assessment.
      If you have any questions, feel free to contact your partner success team.`,
    getPeriodMeta: () => null,
    actionButtonConfig: {
      canStartNextStep({ isPsTeamMember }) {
        // Preconditions to get into this status are defined in getCertificationStatus
        if (isPsTeamMember) return false;
        return true;
      },
      isHidden({ isPsTeamMember }) {
        if (isPsTeamMember) return true;
        return false;
      },
      getLabel() {
        return 'Go';
      }
    },
    assessmentWindowConfig: {
      isHidden: true
    },
    isWithoutCycle: true
  },
  [BASELINE_DRAFT]: {
    title: 'Baseline Assessment in Progress',
    message: `You're off to a great start! Please be sure to fill out all indicators in order for your Baseline Assessment
      to be properly scored. Once complete, you can submit this form to your Partner Success Team for approval.`,
    getPeriodMeta: () => null,
    actionButtonConfig: {
      canStartNextStep() {
        return false;
      },
      isHidden() {
        return false;
      },
      getLabel({ isAdminOrPsTeamMember }) {
        return isAdminOrPsTeamMember ? 'View Assessment' : 'Continue';
      }
    },
    assessmentWindowConfig: {
      isHidden: true
    },
    isWithoutCycle: false
  },
  [BASELINE_REVIEW]: {
    title: 'Baseline Assessment Under Review',
    message: `You’re almost there! If your Partner Success Team has any questions about the information you entered,
      your Baseline Assessment may be returned for additional edits. Otherwise, you’ll be notified of approval and your
      Partner Success Team will reach out about next steps`,
    getPeriodMeta: () => null,
    actionButtonConfig: {
      canStartNextStep() {
        return false;
      },
      isHidden() {
        return false;
      },
      getLabel({ isAdminOrPsTeamMember }) {
        return isAdminOrPsTeamMember ? 'Review' : 'View Assessment';
      }
    },
    assessmentWindowConfig: {
      isHidden: true
    },
    isWithoutCycle: false
  },
  // Hybrid certification status
  [EVALUATION_NOT_STARTED]: {
    title: ({ monthTitle, year }) => `${monthTitle} ${year} Certification Period Not Open`,
    message: `Each calendar year, your organization will have two opportunities to become certified in the Racial
      Equity at Work program: one in February and one in August. When the next opportunity becomes available, you will
      be able to fill out the Certification Assessment. Until then, please keep working hard to implement change in your organization.`,
    getPeriodMeta({ period }) {
      return getMonthYearFromPeriod(period);
    },
    actionButtonConfig: {
      canStartNextStep() {
        return false;
      },
      isHidden() {
        return true;
      },
      getLabel() {
        return null;
      }
    },
    assessmentWindowConfig: {
      isHidden: false
    },
    isWithoutCycle: true
  },
  // Hybrid certification status
  [EVALUATION_NOT_STARTED_OPEN]: {
    title: ({ monthTitle, year }) => `${monthTitle} ${year} Certification Assessment Not Started`,
    message: `Fill out the the Certification Assessment to track your progress towards improved racial equity.
      Change isn't always easy, so please reach out to your Partner Success Team if you require assistance or
      guidance in implementing equitable business practices within your organization.`,
    getPeriodMeta({ period }) {
      return getMonthYearFromPeriod(period);
    },
    actionButtonConfig: {
      canStartNextStep({ isPsTeamMember }) {
        // Preconditions to get into this status are defined in getCertificationStatus
        if (isPsTeamMember) return false;
        return true;
      },
      isHidden({ isPsTeamMember }) {
        if (isPsTeamMember) return true;
        return false;
      },
      getLabel() {
        return 'Go';
      }
    },
    assessmentWindowConfig: {
      isHidden: false
    },
    isWithoutCycle: true
  },
  [EVALUATION_DRAFT]: {
    title: ({ monthTitle, year }) => `${monthTitle} ${year} Certification Assessment In Progress`,
    message: `Keep going! Please be sure to fill out all indicators in order for your Certification Assessment
      to be properly scored. Once complete, you can submit this form to your Partner Success Team to determine if
      your organization has achieved Bronze, Silver, Gold, or Platinum certification status.`,
    getPeriodMeta({ currentStepPosition }) {
      const period = periods.find(({ position }) => position === currentStepPosition);
      return getMonthYearFromPeriod(period);
    },
    actionButtonConfig: {
      canStartNextStep() {
        return false;
      },
      isHidden() {
        return false;
      },
      getLabel({ isAdminOrPsTeamMember }) {
        return isAdminOrPsTeamMember ? 'View Assessment' : 'Continue';
      }
    },
    assessmentWindowConfig: {
      isHidden: false
    },
    isWithoutCycle: false
  },
  [EVALUATION_IN_REVIEW]: {
    title: ({ monthTitle, year }) => `${monthTitle} ${year} Certification Assessment Under Review`,
    message: `You're almost there! If your Partner Success Team has any questions about the information you entered,
      your Baseline Assessment may be returned for additional edits. Otherwise, you'll be notified of approval and
      your Partner Success Team will reach out about next steps.`,
    getPeriodMeta({ currentStepPosition }) {
      const period = periods.find(({ position }) => position === currentStepPosition);
      return getMonthYearFromPeriod(period);
    },
    icon: ClockIcon,
    actionButtonConfig: {
      canStartNextStep() {
        return false;
      },
      isHidden() {
        return false;
      },
      getLabel({ isAdminOrPsTeamMember }) {
        return isAdminOrPsTeamMember ? 'Review' : 'View Assessment';
      }
    },
    assessmentWindowConfig: {
      isHidden: false
    },
    isWithoutCycle: false
  },
  [EVALUATION_APPROVED]: {
    title: ({ monthTitle, year }) => `${monthTitle} ${year} Certification Assessment Complete`,
    message: getCertificationMessage,
    getPeriodMeta({ currentStepPosition }) {
      const period = periods.find(({ position }) => position === currentStepPosition);
      return getMonthYearFromPeriod(period);
    },
    actionButtonConfig: {
      canStartNextStep({ isAssessmentOpen, hasReceivedABadgeInCurrentYear, isAdminOrPsTeamMember }) {
        if (isAdminOrPsTeamMember) return false;
        if (!isAssessmentOpen) return false;
        if (hasReceivedABadgeInCurrentYear) return false;
        return true;
      },
      isHidden({ hasReceivedABadgeInCurrentYear, isAdminOrPsTeamMember }) {
        if (isAdminOrPsTeamMember) return false;
        return hasReceivedABadgeInCurrentYear;
      },
      getLabel({ isAdminOrPsTeamMember }) {
        return isAdminOrPsTeamMember ? 'View Assessment' : 'Go';
      }
    },
    assessmentWindowConfig: {
      isHidden: false
    },
    isWithoutCycle: false
  },
  [EVALUATION_APPROVED_NO_BADGE]: {
    title: ({ monthTitle, year }) => `${monthTitle} ${year} Certification Assessment Complete`,
    message: `Good job! You're on the path to improving racial equity within your organization. With hard work and
    dedication to change, you can achieve Bronze, Silver, Gold, or Platinum certification status.`,
    getPeriodMeta({ currentStepPosition }) {
      const period = periods.find(({ position }) => position === currentStepPosition);
      return getMonthYearFromPeriod(period);
    },
    actionButtonConfig: {
      canStartNextStep() {
        return false;
      },
      isHidden({ isAdminOrPsTeamMember }) {
        if (isAdminOrPsTeamMember) return false;
        return true;
      },
      getLabel({ isAdminOrPsTeamMember }) {
        return isAdminOrPsTeamMember ? 'View Assessment' : null;
      }
    },
    assessmentWindowConfig: {
      isHidden: false
    }
  }
};

export const getStatusConfig = params => {
  const { certification, currentCertificationStep, isBaseline } = params;
  const { hasReceivedABadgeInCurrentYear } = certification;
  const certificationStatus = getCertificationStatus(params);
  const configData = statusConfig[certificationStatus];

  if (!configData) {
    logger.error('Unhandled certification status');
    return;
  }

  const { title, message, getPeriodMeta, icon, actionButtonConfig, assessmentWindowConfig, isWithoutCycle } = configData;
  const actionButtonMeta = getActionButtonMeta({ ...params, actionButtonConfig });
  const assessmentWindowMeta = getAssessmentWindowMeta(hasReceivedABadgeInCurrentYear);
  const certificationCycleYear = new Date().getFullYear();
  const score = currentCertificationStep?.score?.total;
  const isCertified = certificationStatus === EVALUATION_APPROVED && score >= CERTIFIED_SCORE;
  const { period } = assessmentWindowMeta;
  const currentStepPosition = currentCertificationStep?.position;
  const periodMeta = getPeriodMeta({ period, currentStepPosition });

  return {
    certificationStatus,
    isCertified,
    title: typeof title === 'function' ? title(periodMeta) : title,
    isBaseline,
    message: typeof message === 'function' ? message(score) : message,
    certificationCycleYear,
    score,
    assessmentType: certification.assessmentType.title,
    icon,
    actionButton: actionButtonMeta,
    assessmentWindow: {
      ...assessmentWindowMeta,
      isHidden: assessmentWindowConfig.isHidden
    },
    isWithoutCycle
  };
};

function getActionButtonMeta(params) {
  const {
    certification,
    actionButtonConfig,
    isPsTeamMember,
    isAdmin,
    beginNextStep,
    navigateCallback,
    currentCertificationStep,
    skipActionButtonMetaGeneration
  } = params;

  if (skipActionButtonMetaGeneration) return { isHidden: true };

  const { id: certificationId, isAssessmentOpen, hasReceivedABadgeInCurrentYear } = certification;
  const isAdminOrPsTeamMember = isAdmin || isPsTeamMember;
  const actionButtonParams = { isAssessmentOpen, hasReceivedABadgeInCurrentYear, isPsTeamMember, isAdminOrPsTeamMember };
  const beginStepAndNavigate = async () => {
    const stepId = await beginNextStep(certificationId);
    const navigationRoute = getStepRoute(certificationId, stepId);
    return navigateCallback.push(navigationRoute);
  };
  const canStartNextStep = actionButtonConfig.canStartNextStep(actionButtonParams);
  const navigationRoute = getStepRoute(certificationId, currentCertificationStep?.id);
  const navigate = () => navigateCallback.push(navigationRoute);
  const actionHandler = canStartNextStep ? beginStepAndNavigate : navigate;

  return {
    isHidden: actionButtonConfig.isHidden(actionButtonParams),
    canStartNextStep,
    actionHandler,
    label: actionButtonConfig.getLabel(actionButtonParams)
  };
}

function getMonthYearFromPeriod(period) {
  return { monthTitle: period?.title, year: period?.startDate?.getFullYear() };
}
