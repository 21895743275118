import React from 'react';
import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Chip,
  MenuItem,
  Pagination,
  Select,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import { mapValues } from 'lodash';
import { ASSIGNMENT_TYPES } from 'src/constants';
import useCertificationAssignmentType from '../certification/useCertificationAssignmentType';
import CertificationStatusElement from '../certification/CertificationStatusElement';
import themeByAssessmentType from '../assessment/themeByAssessmentType';
import usePagination from '../util/usePagination';

const { PSA, PSM } = ASSIGNMENT_TYPES;
const ROWS_PER_PAGE = 20;
const SORT_OPTIONS = [
  { label: 'A-Z', order: 'asc' },
  { label: 'Z-A', order: 'desc' },
  { label: 'Updated At (Newest first)', order: 'desc', sortBy: 'updatedAt' },
  { label: 'Updated At (Oldest first)', order: 'asc', sortBy: 'updatedAt' }
];
const COLOR_BY_ASSESSMENT_TYPE = mapValues(themeByAssessmentType, theme => theme.palette.primary.main);

const PartnerCount = styled.span`
  background-color: ${({ theme, disabled }) => disabled ? theme.palette.grey[600] : theme.palette.primary.dark};
  color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 ${({ theme }) => theme.spacing(1)};
  font-weight: 600;
  font-size: 12px;
`;

const AssessmentTypeCircle = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ type }) => COLOR_BY_ASSESSMENT_TYPE[type] ?? 'white'};
  border-radius: 50%;
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const StyledSelect = styled(Select)`
  min-width: 80px;
  margin-left: ${({ theme }) => theme.spacing(1)};

  .MuiSelect-select {
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;

const StyledChip = styled(Chip)`
  font-size: 12px;
  margin: ${({ theme }) => theme.spacing(1)};
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  .MuiAccordionSummary-content {
    justify-content: space-between;
  }

  .expand-icon {
    // NOTE: MUI uses this kind of transform by default, but its icon is always appended after the content
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: ${({ theme }) => theme.spacing(1)}
  }

  .Mui-expanded .expand-icon {
    transform: rotate(180deg);
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: ${({ theme }) => theme.spacing(3)};
`;

const PartnerAccordion = ({ partner, isPsTeamMember, ...props }) => {
  const { certifications = [] } = partner;
  const lastCertificationIndex = certifications.length - 1;

  return (
    <Accordion
      disableGutters
      {...props}
    >
      <StyledAccordionSummary>
        <Box display='flex' alignItems='center'>
          <Typography variant='subtitle2'>
            {partner.name}
          </Typography>
          <ChevronDownIcon className='expand-icon' width={16} height={16} />
        </Box>
        <Box display='flex' alignItems='center'>
          <StyledChip
            label={partner.industry?.toUpperCase()}
            size='small'
          />
          {certifications.map(c => (
            <AssessmentTypeCircle
              key={c.id}
              type={c.assessmentType.title}
            />
          ))}
        </Box>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        {certifications.map((c, i) => (
          <CertificationStatusElement
            key={c.id}
            certification={c}
            isPsTeamMember={isPsTeamMember}
            sx={{ mb: (i === lastCertificationIndex ? 0 : 3) }}
          />
        ))}
      </StyledAccordionDetails>
    </Accordion>
  );
};

export default function PartnerList({ partners, sortOrder, setSortOrder, isAdmin, filters }) {
  const certificationAssignmentType = useCertificationAssignmentType();
  const isPsTeamMember = [PSA, PSM].includes(certificationAssignmentType);

  const sortedPartners = [...partners].sort((a, b) => {
    if (sortOrder.sortBy === 'updatedAt') {
      return (sortOrder.order === 'asc' ? 1 : -1) * (
        getPartnerCertificationUpdatedAtBySortOrder(a, sortOrder.order, filters.assessmentTypes) -
      getPartnerCertificationUpdatedAtBySortOrder(b, sortOrder.order, filters.assessmentTypes));
    } else {
      return (sortOrder.order === 'asc' ? 1 : -1) * a.name.localeCompare(b.name);
    }
  });
  const { pageRows: pagePartners, page, setPage, pageCount } = usePagination(sortedPartners, ROWS_PER_PAGE);

  const selectedOption = SORT_OPTIONS.find(option => (
    option.order === sortOrder.order && option.label === sortOrder.label
  ));

  return (
    <>
      <Card>
        {
          isAdmin && <Box p={2} display='flex' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <Typography
                variant='subtitle1'
                component='span'
              >
                My Partners
              </Typography>
              <PartnerCount>
                {sortedPartners.length}
              </PartnerCount>
            </Box>
            <Box display='flex' alignItems='center'>
              <Typography variant='bodyBold'>
                Sort by:
              </Typography>
              <StyledSelect
                value={selectedOption}
                onChange={event => setSortOrder(event.target.value)}
              >
                {SORT_OPTIONS.map(option => (
                  <MenuItem
                    key={option.label}
                    value={option}
                  >
                    <Typography>
                      {option.label}
                    </Typography>
                  </MenuItem>
                ))}
              </StyledSelect>
            </Box>
          </Box>
        }
        {
          pagePartners.map(p => (
            <PartnerAccordion
              key={p.id}
              partner={p}
              isPsTeamMember={isPsTeamMember}
            />
          ))
        }
      </Card>

      <Pagination
        shape='rounded'
        count={pageCount}
        page={page}
        onChange={(event, newPage) => setPage(newPage)}
        sx={{ my: 2, display: 'flex', justifyContent: 'center' }}
      />
    </>
  );
}

function getPartnerCertificationUpdatedAtBySortOrder(partner, sortOrder, assessmentTypes) {
  const isBewOnlyFilterSelected = (assessmentTypes.length === 1 && assessmentTypes[0] === 'BEW');
  const isHewOnlyFilterSelected = (assessmentTypes.length === 1 && assessmentTypes[0] === 'HEW');

  if (!partner.certifications[0]?.lastUpdated && !partner.certifications[1]?.lastUpdated) return new Date(partner.lastUpdated);

  if (isBewOnlyFilterSelected) {
    const bewCertification = partner.certifications.find(certification => certification.assessmentType.title === 'BEW');
    return new Date(bewCertification.lastUpdated);
  } else if (isHewOnlyFilterSelected) {
    const hewCertification = partner.certifications.find(certification => certification.assessmentType.title === 'HEW');
    return new Date(hewCertification.lastUpdated);
  }

  const firstCertificationlastUpdated = new Date(partner.certifications[0]?.lastUpdated);
  const secondCertificationlastUpdated = new Date(partner.certifications[1]?.lastUpdated);

  const isFirstCertificationUpdatedLater = firstCertificationlastUpdated > secondCertificationlastUpdated;
  const lastUpdated = partner.certifications[1]?.lastUpdated
    ? sortOrder === 'asc'
      ? isFirstCertificationUpdatedLater ? secondCertificationlastUpdated : firstCertificationlastUpdated
      : isFirstCertificationUpdatedLater ? firstCertificationlastUpdated : secondCertificationlastUpdated
    : firstCertificationlastUpdated;

  return lastUpdated;
}

export { PartnerCount, StyledSelect, getPartnerCertificationUpdatedAtBySortOrder };
