import create from 'zustand';
import { devtools } from 'zustand/middleware';
import appointment from './appointment/appointmentStore';
import assessment from './assessment/assessmentStore';
import auth from './auth/authStore';
import certification from './certification/certificationStore';
import comment from './comment/commentStore';
import notification from './notification/notificationStore';
import partner from './partner/partnerStore';
import user from './user/userStore';
import { immer } from '../store/middleware';

const slices = { appointment, assessment, auth, certification, comment, notification, partner, user };

/**
 * Namespace `set` and `get` methods of the slice to the slice name. Root state will still be accessible through the
 * third parameter.
 * @param {string} key The name of the slice.
 * @param {Function} sliceFactory The slice factory.
 * @returns {Function} The slice factory function, called with namespaced `set`, namespaced `get` and root `get`
 * functions as parameters.
 */
function namespaceSlice(key, sliceFactory) {
  return (set, get) => {
    const keyedGet = () => get()[key];
    const keyedSet = (partial, name = 'action', replace = false) => {
      const state = keyedGet();
      const nextState = typeof partial === 'function' ? partial(state) : state;
      const keyedName = `${key.toUpperCase()}/${name}`;

      if (state === nextState) {
        return;
      }

      set({ [key]: replace ? nextState : { ...state, ...nextState } }, keyedName);
    };

    return sliceFactory(keyedSet, keyedGet, get);
  };
}

const createStore = (set, get) => Object.entries(slices).reduce((acc, [key, slice]) => {
  const createSlice = namespaceSlice(key, slice);

  return { ...acc, [key]: createSlice(set, get) };
}, {});

export default create(devtools(immer((set, get) => ({
  ...createStore(set, get),
  reset: () => set(createStore(set, get))
}))));
