import React from 'react';
import { Grid, Link, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Logo from 'src/components/Logo';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Twitter from '@mui/icons-material/Twitter';
import Instagram from '@mui/icons-material/Instagram';
import LinkedIn from '@mui/icons-material/LinkedIn';

const LINKS = [
  { label: 'Terms of Use', to: 'https://mlt.org/rew-terms-of-use' },
  { label: 'Privacy Policy', to: 'https://mlt.org/privacy-policy/' },
  { label: 'Contact Us', to: 'https://mlt.org/contact-us/' }
];

const SOCIALS = [
  {
    label: 'Facebook',
    to: 'https://www.facebook.com/MLTOrg',
    iconComponent: FacebookRoundedIcon
  },
  {
    label: 'Twitter',
    to: 'https://twitter.com/MLTOrg',
    iconComponent: Twitter
  },
  {
    label: 'Instagram',
    to: 'https://www.instagram.com/mltorg/',
    iconComponent: Instagram
  },
  {
    label: 'LinkedIn',
    to: 'https://www.linkedin.com/school/management-leadership-for-tomorrow/',
    iconComponent: LinkedIn
  }
];

export default function AppFooter() {
  const Footer = styled(props => (
    <Grid container {...props} />
  ))`
    align-items: center;
    height: 65px;
    background-color: #fff;
    padding: ${({ theme }) => theme.spacing(0, 2.5)};
    border-top: 1px solid #969DE1;
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      height: 165px;
      gap: ${({ theme }) => theme.spacing(2)};
    }
  `;

  const LogoLinksWrapper = styled(props => (
    <Grid item {...props} />
  ))`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(3)};
    @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      flex-basis: 66.66667%;
    }
  `;

  const LogoWrapper = styled(props => (
    <Link target='_blank' href='https://mlt.org/' {...props} />
  ))`
    display: flex;
    align-items: center;
  `;

  const Copyright = styled(props => (
    <Typography component="p" variant="microtext1" {...props} />
  ))`
    color: ${({ theme }) => theme.palette.tertiary.dark};
    white-space: nowrap;
  `;

  const Links = styled(props => (
    <Grid container {...props} />
  ))`
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing(3)};
    margin-left: ${({ theme }) => theme.spacing(2)};
  `;

  const StyledLink = styled(props => (
    <Link target='_blank' variant='microtext1' {...props} />
  ))`
    color: ${({ theme }) => theme.palette.rew.main};
    text-decoration: underline;
  `;

  const Socials = styled(props => (
    <Grid container item {...props} />
  ))`
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing(3)};
    @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      justify-content: center;
      gap: ${({ theme }) => theme.spacing(3.75)};
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
      flex-basis: 33.333333%;
    }
  `;

  const Social = styled(props => (
    <Link target='_blank' {...props} />
  ))`
    display: flex;
    color: ${({ theme }) => theme.palette.tertiary.dark};
    transform: scale(1.2);
  `;

  return (
    <Footer>
      <LogoLinksWrapper xs={12} sm={8} >
        <LogoWrapper>
          <Logo src='/static/logo-mlt.png' width={56} />
        </LogoWrapper>
        <Copyright>
          &copy; Copyright MLT { new Date().getFullYear()}
        </Copyright>
        <Links>
          {
            LINKS.map(link => (
              <StyledLink key={link.label} href={link.to}>
                { link.label }
              </StyledLink>
            ))
          }
        </Links>
      </LogoLinksWrapper>
      <Socials xs={12} sm={4}>
        {
          SOCIALS.map(social => (
            <Social key={social.label} href={social.to}>
              <social.iconComponent alt={social.label} />
            </Social>
          ))
        }
      </Socials>
    </Footer>
  );
}
